import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router';
import { useAuthState } from '../../userContext';

/**
 * This component sends a tracking event to Pendo and then redirects to the 404 page.
 * ℹ️ Please use this component any time you want to redirect to the 404 page.
 */
const RedirectTo404 = ()=>{
	const {md5Hash, orgCode} = useAuthState();
	
	if( window.pendo ){
		window.pendo.track( 'REDIRECTING_TO_404',{
			attemptedPath: `${window.location.host}${window.location.pathname}`,
			secureId: md5Hash || 'secureId not set',
			orgCode: orgCode || 'orgCode not set'
		} )
	}

	return <Navigate to='/404' />;
}

PropTypes.RedirectTo404 = {
	children: PropTypes.node.isRequired
}

export default RedirectTo404;