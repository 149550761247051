import { CssBaseline, ThemeProvider } from '@mui/material';
import { BuildTheme } from '@pg/shared-ui';
import '@pg/shared-ui/src/assets/fonts/ESKlarheitGrotesk/ESKlarheitGrotesk.css';
import Amplify from 'aws-amplify';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import awsconfig from './aws-exports';
import { graphQlEndpoint } from './constants';
import { LoadingProvider } from './loadingContext';
import { OrgProvider, initialState as orgInitState } from './orgContext';
import Router from './routes/Router';
import { AuthProvider, initialState as userInitState } from './userContext';

awsconfig.aws_appsync_graphqlEndpoint = graphQlEndpoint();
Amplify.configure( awsconfig );

const App = () => {
	const theme = BuildTheme();
	
	window?.pendo?.initialize( {
		visitor: {
			id: 'VISITOR-UNIQUE-ID',
		}
	} );

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<LoadingProvider>
				<OrgProvider initialState={orgInitState}>
					<AuthProvider initialState={userInitState}>
						<Router />
					</AuthProvider>
				</OrgProvider>
			</LoadingProvider>
		</ThemeProvider>
	);
};

export default App;
