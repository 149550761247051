import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { attachIdentityToAnalytics } from '../api/analyticsService';
import {
	ERRORS,
	handleAxiosErrors,
	handleGraphQlErrors,
	requestCreatePayment,
	requestCreatePaymentFinancing,
	requestCreatePaymentPlan,
	requestCreateWallet,
	requestInvoicesWithCredits,
	requestWallets,
	verifyIdentity,
	verifyIdentityOrgCode
} from '../api/services';
import { transformPaymentPlanPayload } from '../api/transformPaymentPayload';
import { LOGIN_REFRESH, LOGIN_REFRESH_MINIUM_TIME } from '../constants';
import { getUserObject } from './reducer';

export function storeMd5Hash( dispatch, md5hash ) {
	dispatch( { type: 'STORE_HASH', payload: md5hash } );
	localStorage.setItem( 'md5hash', JSON.stringify( md5hash ) );
}

export function storeOrgCode( dispatch, orgCode ) {
	dispatch( { type: 'STORE_ORG_CODE', payload: orgCode } );
	localStorage.setItem( 'orgCode', JSON.stringify( orgCode ) );
}

export async function authenticateUser( dispatch, authPayload, loginViaRefresh = false ) {
	try {
		dispatch( { type: 'REQUEST_AUTH' } );
		const { data } = await verifyIdentity( authPayload );
		if ( data.access_token ) {
			dispatch( { type: 'AUTH_SUCCESS', payload: getUserObject( data ) } );
			localStorage.setItem( 'currentUser', JSON.stringify( data ) );

			return true;
		}
	} catch ( error ) {
		const responseMessage = handleAxiosErrors( error );
		const message =
			responseMessage === 'No matching contact found' ? ERRORS.verification_failed : ERRORS.default;
		dispatch( { type: 'AUTH_ERROR', error: message, loginViaRefresh } );
		return false;
	}
	dispatch( { type: 'AUTH_ERROR', error: ERRORS.default, loginViaRefresh } );
	return false;
}

export async function authenticateUserOrgCode( dispatch, authPayload, loginViaRefresh = false ) {
	try {
		dispatch( { type: 'REQUEST_AUTH' } );

		const { data } = await verifyIdentityOrgCode( authPayload );

		if ( data.access_token ) {
			dispatch( { type: 'AUTH_SUCCESS', payload: getUserObject( data ) } );

			localStorage.setItem( 'currentUser', JSON.stringify( data ) );

			return true;
		}

	} catch ( error ) {
		const responseMessage = handleAxiosErrors( error );
		const message = responseMessage === 'No matching org found' ? ERRORS.verification_failed : ERRORS.default;

		dispatch( { type: 'AUTH_ERROR', error: message, loginViaRefresh } );

		return false;
	}

	dispatch( { type: 'AUTH_ERROR', error: ERRORS.default, loginViaRefresh } );

	return false;
}

export async function getInvoices( dispatch, authToken ) {
	try {
		localStorage.removeItem( 'payment' );
		let { invoices } = await requestInvoicesWithCredits( authToken, 'Debit' );
		// Remove invoices with no invoice id - bad data
		invoices = invoices.reduce( ( invoiceList, invoice ) => {
			if ( invoice?.id ) {
				const subtotal = !invoice?.isIntegration && invoice.discountAmount && Number( invoice.discountAmount ) > 0
					? ( ( Number( invoice.amountOwed ) * 100 + Number( invoice.discountAmount ) * 100 ) / 100 ) : null;
				return [ ...invoiceList, { ...invoice, subtotal } ];
			}
			return invoiceList;
		}, [] );
		// Pre-select invoices if there is only 1
		let total = 0;
		if ( invoices.length === 1 ) {
			invoices = [ { ...invoices[ 0 ], checked: true } ];
			total = invoices[ 0 ].amountOwed;
		}
		dispatch( { type: 'GET_INVOICES_SUCCESS', payload: { invoices, total } } );
		return { invoices, total };
	} catch ( response ) {
		const responseMessage = handleGraphQlErrors( response );
		const message = responseMessage.match( /statusCode.*401.*Unauthorized/g )
			? ERRORS.unauthorized
			: ERRORS.default;
		dispatch( { type: 'GET_INVOICES_ERROR', error: message } );
	}
	return {};
}

export async function getCreditInvoices( authToken ) {
	try {
		const { invoices, associatedInvoices } = await requestInvoicesWithCredits( authToken, 'Credit' );
		// Remove invoices with no invoice id - bad data
		return { invoices, associatedInvoices };
	} catch ( response ) {
		const responseMessage = handleGraphQlErrors( response );
		const message = responseMessage.match( /statusCode.*401.*Unauthorized/g )
			? ERRORS.unauthorized
			: ERRORS.default;
		console.error( 'Error fetching credit invoices:', message );
		return null;
	}
}

export function updateInvoices( dispatch, updatedInvoices, selectedInvoices, total, creditsApplied ) {
	dispatch( { type: 'UPDATE_INVOICES', payload: { updatedInvoices, selectedInvoices, total, creditsApplied } } );
}

export async function getWallets( dispatch, authToken, acceptAch, acceptCards ) {
	dispatch( { type: 'GET_WALLETS_PENDING' } );
	try {
		let wallets = await requestWallets( authToken );

		if ( wallets?.length > 0 ) {
			wallets.sort( ( a, b ) => Number( b.defaultMethod ) - Number( a.defaultMethod ) ); // put default methods first
			const availableTypes = [];
			if ( acceptAch ) availableTypes.push( 'ach' );
			if ( acceptCards ) availableTypes.push( 'card' );
			wallets = availableTypes.length > 0 ? wallets.reduce( ( walletList, walletItem ) => {

				if ( availableTypes.includes( walletItem.methodType ) ) {
					const formattedWallet = {
						...walletItem,
						accountHolder: walletItem.accountHolder ? decodeURIComponent( walletItem.accountHolder ).replace( /\+/g, ' ' ) : '',
						nickname: walletItem.nickname ? decodeURIComponent( walletItem.nickname ).replace( /\+/g, ' ' ) : ''
					};
					walletList.push( formattedWallet );
				}
				return walletList;
			  }, [] )
			  : [];
		}
		dispatch( { type: 'GET_WALLETS_SUCCESS', payload: { wallets } } );
	} catch ( response ) {
		const responseMessage = handleGraphQlErrors( response );
		const message = responseMessage.match( /statusCode.*401.*Unauthorized/g )
			? ERRORS.unauthorized
			: ERRORS.default;
		dispatch( { type: 'GET_WALLETS_ERROR', error: message } );
	}
	return false;
}

export async function makePayment( dispatch, authToken, payload ) {
	const paymentType = payload && 'financingDetails' in payload ? 'financing' : 'payment';

	try {
		localStorage.removeItem( 'payment' );
		dispatch( { type: 'REQUEST_PAYMENT' } );

		let paymentResponse;

		switch ( paymentType ) {
			case 'financing':
				paymentResponse = await requestCreatePaymentFinancing( payload, authToken );
				break;
			case 'payment':
			default:
				paymentResponse = await requestCreatePayment( payload, authToken );
		}

		if ( paymentResponse.status.toString() === '200' || paymentResponse.status.toString() === '207' ) {
			const payment = { ...paymentResponse, paymentType };
			dispatch( { type: 'PAYMENT_SUCCESS', payload: payment } );
			localStorage.setItem( 'payment', JSON.stringify( payment ) );
		} else {
			const errorMessage = paymentResponse?.message ?? ERRORS.paymentRetryError[ paymentType ];
			dispatch( { type: 'PAYMENT_ERROR', error: errorMessage } );
		}
	} catch ( response ) {
		dispatch( { type: 'PAYMENT_ERROR', error: ERRORS.paymentRetryError[ paymentType ] } );
	}

	return false;
}

export async function makePaymentPlan( { dispatch, user, values, selectedInvoices, providerId } ) {
	try {
		let paymentPlanResponse = null;

		const { payload, authToken, paymentMethod } = transformPaymentPlanPayload( {
			user,
			values,
			selectedInvoices,
			providerId
		} );

		if ( !( payload && authToken && paymentMethod ) ) {
			dispatch( { type: 'PAYMENT_ERROR', error: ERRORS.attemptPaymentError } );

			return;
		}

		// UPDATE - This should always be stored payment methods with a wallet ID
		if ( values?.paymentMethod === 'Stored Payment Methods' && values?.walletId ) {
			// If we are using wallet, should be fine to create the payment plan
			paymentPlanResponse = await requestCreatePaymentPlan( { payload, authToken } );
		} else {
			// NOTE - this should never fire
			dispatch( { type: 'PAYMENT_ERROR', error: ERRORS.paymentPlanWalletError } );
			return;
		}

		if ( paymentPlanResponse.status === 'SUCCESS' ) {
			dispatch( {
				type: 'PAYMENT_SUCCESS', payload: {
					paymentPlanPayload: payload,
				},
			} );
		} else {
			dispatch( {
				type: 'PAYMENT_ERROR_PAYMENT_PLAN',
				error: `${ERRORS.paymentPlanInitialPaymentError} (ERROR: ${paymentPlanResponse.refId})`,
			} );
		}

	} catch ( error ) {
		dispatch( { type: 'PAYMENT_ERROR', error: ERRORS.attemptPaymentError } );
	}
}

export async function createWallet( { dispatch, authToken, payload } ) {
	try {
		dispatch( { type: 'CREATE_WALLET_PENDING' } );

		const { status, wallet } = await requestCreateWallet( { payload, authToken } );

		if ( status.toString() !== '200' ) {
			dispatch( { type: 'CREATE_WALLET_ERROR', error: ERRORS.createWalletError } );
		} else {
			dispatch( { type: 'CREATE_WALLET_SUCCESS', payload: wallet } );
		}
	} catch ( response ) {
		dispatch( { type: 'CREATE_WALLET_ERROR', error: ERRORS.createWalletError } );
	}

	return false;
}

// TODO: Logout should redirect user to the login page. (secure id vs org code.)
export function logout( dispatch ) {
	localStorage.removeItem( 'md5hash' );
	localStorage.removeItem( 'orgCode' );
	localStorage.removeItem( 'currentUser' );
	localStorage.removeItem( 'payment' );

	dispatch( { type: 'LOGOUT' } );
}

export function useRefreshLogin( {dispatch, user, md5hash, orgCode = ''} ) {
	const navigate = useNavigate();
	const timer = useRef( null );
	useEffect( () => {
		if ( timer.current ) {
			clearInterval( timer.current );
			timer.current = undefined;
		}
		if ( user ) {
			const payload = {
				last: user?.lastName,
				dob: user?.dob,
				md5hash
			};
			// ℹ️ md5hash ( secureId ) is the preferred visitorId. If it doesn't exist, use contactId ( MOD-603 )		
			attachIdentityToAnalytics(  md5hash || user.contactId );
			// expiresAt is in seconds, need to convert now to seconds, wait is in milliseconds
			let wait = ( user.expiresAt * 1000 ) - new Date().getTime();
			if ( wait < LOGIN_REFRESH_MINIUM_TIME ) {
				logout( dispatch );
			} else {
				if ( wait < LOGIN_REFRESH ) {
					wait = 0;
				}
				if ( !Number.isNaN( wait ) ) {
					timer.current = setTimeout( async () => {
						const response = await authenticateUser( dispatch, payload, true );
						if ( !response ) {
							navigate( orgCode ? `/org/${orgCode}` : '/contact' )
						}
					}, wait );

					return () => clearInterval( timer.current );
				}
			}
		}
		return () => {
		};
	}, [ user, md5hash ] );
}
