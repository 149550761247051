import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from '../../userContext';

const RequireAuth = ( { children } ) => {
	const { md5hash, orgCode, user } = useAuthState();
	const location = useLocation();

	if ( !user?.authToken ) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		if ( md5hash?.contact ) {
			return <Navigate to='/contact' state={{ from: location }} replace />;
		}

		if ( orgCode ) {
			return <Navigate to='/org' state={{ from: location }} replace />;
		}

		return <Navigate to='/' state={{ from: location }} replace />;
	}

	return children;
};

RequireAuth.propTypes = {
	children: PropTypes.element.isRequired
};

export default RequireAuth;
