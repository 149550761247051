import { Alert, Grid, Link, Typography } from '@mui/material';
import { BaseCard, LockSvg } from '@pg/shared-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { ERRORS } from '../../api/services';
import { SECURITY_URL } from '../../constants';
import VerifyIdentityForm from '../forms/VerifyIdentityForm';
import VerifyPayCodeForm from '../forms/VerifyOrgCodeForm';

const VerifyIdentityCard = ( { errorMessage, loginViaRefresh, isSm, type } ) => (
	<BaseCard sx={{ pt: isSm ? 2 : 6}}>
		<Grid
			container
			spacing={0}
			direction='column'
			alignItems='center'
		>
			<Grid item
				container
				spacing={1}
				direction='column'
			>
				<Grid item sx={{ my: 1 }}>
					<Typography
						align='center'
						variant='h5'
					>
						Verify Your Details
					</Typography>
				</Grid>
				<Grid item>
					<Typography
						align='center'
						variant='body1'
						sx={{ mb: 4, mx: 2 }}
					>
						First, we need to make sure it&apos;s you.<br/>Please enter your information.
					</Typography>
				</Grid>
				{errorMessage ?
					<Grid item sx={{ mb: 2 }}>
						<Alert
							severity={loginViaRefresh ? 'info' : 'error'}>{loginViaRefresh ? ERRORS.refresh_failed : errorMessage}
						</Alert>
					</Grid>
					:
					null}
				<Grid item sx={{ mb: 1 }}>
					{ type === 'org' && <VerifyPayCodeForm /> }
					{ type === 'contact' && <VerifyIdentityForm /> }
				</Grid>
				<Grid
					item
					sx={{ py: 1, pl: isSm ? '10px !important' : 0, pr: isSm ? '10px' : 0 }}
					align='center'
				>
					<Grid
						item
						sx={{ display: 'inline', position: 'relative', top: '1px' }}
						align='center'
					>
						<LockSvg/>
					</Grid>
					<Typography
						variant='captionGrey'
						sx={{ pr: 0.5 }}
					>
						&nbsp;&nbsp;Security is our number one priority.
					</Typography>
					<Grid item sx={{ display: 'inline-block' }}>
						<Link
							underline='none'
							href={`${SECURITY_URL}?utm_source=csp`}
							variant='button'
							target='_blank'
							rel='noopener'
						>
							Learn more
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</BaseCard>
);

VerifyIdentityCard.propTypes = {
	type: PropTypes.oneOf( [ 'contact', 'org' ] ).isRequired,
	errorMessage: PropTypes.string,
	loginViaRefresh: PropTypes.any,
	isSm: PropTypes.bool,
};

export default VerifyIdentityCard;
