import { useTheme } from '@emotion/react';
import { Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { BaseCard, PageContainer } from '@pg/shared-ui';
import React from 'react';
import ErrorIconSvg from '../assets/ErrorIconSvg';

const Landing = () => {
	const theme = useTheme();
	const isXs = useMediaQuery( theme.breakpoints.only( 'xs' ), { noSsr: true } );

	return (
		<PageContainer title='Payground: Landing' description='a splash page with helpful information to get to the correct authentication page.'>
			<Grid
				container
				justifyContent='center'
			>
				<Grid item>
					<BaseCard sx={{ maxWidth: isXs ? '400px' : '1000px' }}>
						<Grid
							container
							spacing={2}
							direction='column'
							alignItems='center'
							sx={{ p: isXs ? 0 : 3 }}
						>
							<Grid item container spacing={1}>
								<Grid item container xs={12} direction={isXs ? 'column' : 'row'} alignItems='center' wrap='nowrap' gap='4rem' >
									<div style={{width: isXs ? '12rem' : '15rem'}}>
									 <ErrorIconSvg/>
									 </div>
									 <Grid item xs zeroMinWidth>
									 <Typography align='center' variant='h2' textAlign='left'><strong>Oops!</strong></Typography>
									 <Typography align='center' variant='h5' textAlign='left'><strong>{'We couldn\'t route you to the entered URL.'}</strong></Typography>
									 </Grid>
								</Grid>
								
								<Grid item xs={12} justifyItems='center' style={{maxWidth: '700px', margin: '1rem auto 2rem'}}>
									<Typography>
									Each user accessing the platform is unique, and we cannot route you based on the information provided. Please consider the following steps to determine how to proceed:
									</Typography>
									<ul>
										<li>
											<Typography>
											If you received a text or email, please click the URL instead of entering it manually in your browser.
											</Typography>
										</li>
										<li>
											<Typography>
											If you have a paper statement, ensure that the URL is accurate.
											</Typography>
										</li>
										<li>
											<Typography>
											If neither of these solutions resolves the issue, please reach out to us at <Link href="mailto:support@payground.com" >support@payground.com</Link> or call <Link href="tel:18003854556" >+1 (800) 385-4556</Link>.
											</Typography>
										</li>
									</ul>
								</Grid>
							</Grid>
						</Grid>
					</BaseCard>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Landing;
