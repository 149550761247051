const getOrg = () => {
	try {
		const storedOrg = localStorage.getItem( 'currentOrg' );
		if ( storedOrg ) {
			const parsedOrg = JSON.parse( storedOrg );
			// Ensure org is an object
			if ( typeof parsedOrg === 'object' && parsedOrg !== null ) {
				return parsedOrg;
			}
		}
	} catch ( e ) {
		// Parsing failed, keep org as {}
		console.error( 'Issue parsing org: ', e );
	}
	return null;
}

export const initialState = {
	org: getOrg(),
	loading: false,
	errorMessage: null
};

export const OrgReducer = ( initState, action ) => {
	switch ( action.type ) {
		case 'REQUEST_ORG':
			return {
				...initState,
				loading: true
			};
		case 'ORG_SUCCESS':
			return {
				...initState,
				org: action.payload,
				loading: false
			};
		case 'LOGOUT':
			return {
				...initState,
				org: null
			};
		case 'ORG_ERROR':
			return {
				...initState,
				loading: false,
				errorMessage: action.error
			};
		default:
			throw new Error( `Unhandled action type: ${action.type}` );
	}
};
