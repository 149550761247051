import { Grid } from '@mui/material';
import { PageContainer } from '@pg/shared-ui';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getOrganizationByOrgCode, resetOrg, useOrgDispatch, useOrgState } from '../../orgContext';
import { logout, storeOrgCode, useAuthDispatch, useAuthState } from '../../userContext';

import useResponsive from '../../hooks/useResponsive';

import OrgDetailsBox from '../../components/boxes/OrgDetailsBox';
import VerifyIdentityCard from '../../components/cards/VerifyIdentityCard';
import Landing from '../Landing';


/**
 * This is a sister component to VerifyIdentity.js. They are identical in functionality, but this one is for verifying via the org.
 * TODO: Combine the two components into one after a state management refactor.
 */
const VerifyOrgCode = () => {
	const { errorMessage, loginViaRefresh, orgCode: userOrgCode, user } = useAuthState();
	const { isSm, isMd, isLg, isXl } = useResponsive();
	const { org } = useOrgState();
	const queryParams = useParams();
	const authDispatch = useAuthDispatch();
	const orgDispatch = useOrgDispatch();
	const navigate = useNavigate();

	const orgCode = queryParams?.orgCode;

	// save orgCode
	useEffect( () => {
		const userExpiresAt = user?.expiresAt;
		const currentTime = new Date().getTime() / 1000;

		if ( orgCode && ( userOrgCode !== orgCode ) ) {
			logout( authDispatch );
			resetOrg( orgDispatch );
			storeOrgCode( authDispatch, orgCode );
			getOrganizationByOrgCode( orgDispatch, orgCode );
		} else if ( !loginViaRefresh && orgCode && user && ( userExpiresAt > currentTime ) ) {
			navigate( '/invoices' );
		} else if ( !orgCode ) {
			logout( authDispatch );
		}
	}, [ orgCode ] );


	if( !orgCode || !org ) {
		return <Landing />;
	}

	return (
		<PageContainer title='Payground: Verify Your Details' description='this is the identity verification page'>
			<Grid
				container
				justifyContent='center'
				sx={{
					mt: isLg ? 0 : 10,
					mb: isSm ? '150px' : isMd ? '200px' : isLg ? '300px' : 0,
					visibility: org ? 'visible' : 'hidden'
				}}
			>
				<Grid
					item
					sx={ {
						mr: isXl ? 10 : 0,
						maxWidth: isSm ? '100%' : isMd ? '95%' : isLg ? '70%' : isXl && '45%'
					} }
				>
					<OrgDetailsBox
						isLg={isLg}
						isSm={isSm}
						isXl={isXl}
						org={org}
					>
						<VerifyIdentityCard
							errorMessage={errorMessage}
							isSm={isSm}
							loginViaRefresh={loginViaRefresh}
							type='org'
						/>
					</OrgDetailsBox>
				</Grid>
				{ ( isXl ) && (
					<Grid item sx={{ maxWidth: isSm ? '200px' : '500px' }}>
						<VerifyIdentityCard
							errorMessage={errorMessage}
							isSm={isSm}
							loginViaRefresh={loginViaRefresh}
							type='org'
						/>
					</Grid>
				) }
			</Grid>
		</PageContainer>
	)
};

export default VerifyOrgCode;